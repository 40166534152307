.sidebar {
    width: 180px;
    min-width: 180px;
    min-height: 100vh;
    background: #FFF;
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and (max-width: 1024px) {
    .sidebar {
        box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
        z-index: 1;
        min-width: 150px;
        height: 100vh;
    }
}

.sidebar-menu {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.sidebar-menu-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 30px;
}

.sidebar-menu-item:hover {
    background: #F0F0F0;
}

.sidebar-menu-item-active {
    background: #F0F0F0;
}

.sidebar-menu-icon1 {
    background: url("../../images/sidebar/dashboard.png");
    background-size: contain;
    width: 30px;
    height: 21px;
}

.sidebar-menu-icon2 {
    background: url("../../images/sidebar/users.png") no-repeat center;
    background-size: contain;
    width: 30px;
    height: 24px;
}

.sidebar-menu-icon3 {
    background: url("../../images/sidebar/reports.png");
    background-size: contain;
    width: 30px;
    height: 21px;
}

.sidebar-menu-icon4 {
    background: url("../../images/sidebar/payout.png");
    background-size: contain;
    width: 30px;
    height: 21px;
}

.sidebar-menu-icon5 {
    background: url("../../images/sidebar/news.png");
    background-size: contain;
    width: 30px;
    height: 21px;
}

.sidebar-menu-icon6 {
    background: url("../../images/sidebar/epins.png");
    background-size: contain;
    width: 30px;
    height: 21px;
}

.sidebar-menu-icon7 {
    background: url("../../images/sidebar/transactions.png");
    background-size: contain;
    width: 30px;
    height: 21px;
}

.sidebar-menu-icon8 {
    background: url("../../images/sidebar/orders.png");
    background-size: contain;
    width: 30px;
    height: 21px;
}

.sidebar-menu-icon9 {
    background: url("../../images/sidebar/translations.png");
    background-size: contain;
    width: 30px;
    height: 21px;
}

.sidebar-menu-icon10 {
    background: url("../../images/sidebar/education.png");
    background-size: contain;
    width: 30px;
    height: 21px;
}

.sidebar-menu-icon11 {
    background: url("../../images/sidebar/resources.png");
    background-size: contain;
    width: 30px;
    height: 21px;
}

.sidebar-menu-icon12 {
    background: url("../../images/sidebar/events.png") no-repeat center;
    background-size: contain;
    width: 30px;
    height: 20px;
}

.sidebar-menu-icon13 {
    background: url("../../images/sidebar/exchange.png") no-repeat center;
    background-size: contain;
    width: 30px;
    height: 21px;
}

.sidebar-menu-icon14 {
    background: url("../../images/sidebar/epins.png") no-repeat center;
    background-size: contain;
    width: 30px;
    height: 21px;
}

.sidebar-menu-icon15 {
    background: url("../../images/sidebar/epins.png") no-repeat center;
    background-size: contain;
    width: 30px;
    height: 21px;
}
.sidebar-menu-icon16 {
    background: url("../../images/sidebar/epins.png") no-repeat center;
    background-size: contain;
    width: 30px;
    height: 21px;
}