.user-statements {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.user-statements-main-info {
    display: flex;
    align-items: flex-start;
    column-gap: 70px;
    margin-bottom: 20px;
}

.user-statements-main-info > table > tbody > tr > td {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 10px;
}

.user-statements-blocks-container {
    display: flex;
    align-items: flex-start;
    column-gap: 50px;
}

@media screen and (max-width: 1024px){
    .user-statements-blocks-container {
        flex-direction: column;
        row-gap: 20px;
    }
}

.user-statements-blocks-container > div {
    background: #F0F0F0;
    padding: 20px;
}

.user-statements-table {
    display: flex;
    align-items: flex-start;
    column-gap: 50px;
}

.user-statements-table > div {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.user-statements-table-title {
    font-weight: 700;
}