.translations {
    position: relative;
}

.translations-form {
    width: 500px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

@media screen and (max-width: 1024px){
    .translations-form {
        width: 100%;
    }
}

.translations-filter {
    margin-bottom: 20px;
    width: 200px;
}

.reset-filters-translations {
    bottom: 20px !important;
}