.resources-form {
    width: 80%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

@media screen and (max-width: 1024px) {
    .resources-form {
        width: 100%;
    }
}

.resources-form-button-container {
    width: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.resources-form-title {
    margin-bottom: 5px;
}

@media screen and (max-width: 1024px) {
    .resources-td-link {
        max-width: 150px;
        overflow-wrap: break-word;
    }
}
