.dashboard-layout {
    background: #F4F4F4;
    height: auto;
    display: flex;
}

@media screen and (max-width: 1000px){
    .dashboard-layout {
        height: 100%;
    }
}

.dashboard-content {
    width: 1260px;
    padding: 20px;
    box-sizing: border-box;
}

@media screen and (max-width: 1000px){
    .dashboard-content {
        width: 100%;
        margin: 0;
        padding: 0;
        padding-bottom: 103px;
    }
}