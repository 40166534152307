.user-stats {
    width: 30%;
}

@media screen and (max-width: 1024px){
    .user-stats {
        width: 100%;
    }
}

.user-stats-table > tbody > tr > td {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 7px;
}