.crypto-api-form {
    width: 500px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px auto 0;
    padding: 30px;
}

.crypto-api-form > form {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 30px;
}

.crypto-api-form > form > div {
    width: 100%;
}

.crypto-api-input-label {
    font-size: 14px;
    color: gray;
}

.crypto-api-form-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}