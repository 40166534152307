.tabs {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width: 1024px){
    .tabs {
        margin-top: 20px;
        column-gap: 5px;
    }
}

.tabs-item {
    display: flex;
    align-items: center;
    justify-content: center;
    /*background: #BE52F2;*/
    background: #BE52F2;
    color: #FFF;
    width: 140px;
    height: 40px;
    cursor: pointer;
}

@media screen and (max-width: 1024px){
    .tabs-item {
        width: 100px;
        height: 30px;
        font-size: 12px;
    }
}

.tabs-item:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 3px;
}

.tabs-item-active {
    /*background: #DBA5F5;*/
    background: #6101ee;
    box-shadow: none !important;
}