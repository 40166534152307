.reports {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.report-row-caption {
    width: 100%;
    max-width: 100%;
    font-weight: normal;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.report-row-caption-expanded {
    font-weight: 600;
}

.report-row-caption > div:nth-child(1) {
    width: 100px;
}

.report-row-caption > div:nth-child(3) {
    width: 50px;
}

.report-row-caption > div:nth-child(5) {
    width: 30px;
}

.report-row-caption > div:nth-child(7) {
    width: 100px;
}

.report-row-wallet-caption {
    width: 100%;
    max-width: 100%;
    font-weight: normal;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.report-details {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.report-details-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}

.report-details-row > div:nth-child(1) {
    text-align: left;
}

.report-details-row > div {
    width: 10%;
    min-width: 90px;
    text-align: right;
}

.report-details-separator {
    height: 1px;
    background: #848484;
}

.reports-negative-value {
    color: #FF0000;
}