.user-info {
    display: flex;
    column-gap: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width: 1024px){
    .user-info {
        flex-direction: column;
        row-gap: 20px;
    }
}