.login-form {
    background: #FFF;
    width: 400px;
    padding: 20px 20px;
    border-radius: 5px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 1024px){
    .login-form {
        max-width: 95%;
    }
}

.login-title {
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
}