.news-form {
    width: 80%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

@media screen and (max-width: 1024px){
    .news-form {
        width: 100%;
    }
}

.news-form-button-container {
    width: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
}