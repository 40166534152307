.top-panel {
    height: 70px;
    width: 100%;
    background: #FFFFFF;
    color: #303030;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 20px 20px;
}

@media screen and (max-width: 1024px) {
    .top-panel {
        height: 40px;
        column-gap: 10px;
    }
}

.top-panel-right-menu {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.top-panel-right-menu-item {
    cursor: pointer;
}

.top-panel-user-item {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.top-panel-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #438AFE;
}
