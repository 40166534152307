.user-edit {
    width: 70%;
}

@media screen and (max-width: 1024px){
    .user-edit {
        width: 100%;
    }
}

.user-edit-form {
    width: 80%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

@media screen and (max-width: 1024px){
    .user-edit-form {
        width: 100%;
    }
}

.user-edit-form-button-container {
    width: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.user-edit-form-switch-container {
    display: flex;
    align-items: center;
    column-gap: 25px;
    row-gap: 25px;
    margin-top: 40px;
    width: 50%;
    text-align: center;
}

.user-edit-topup-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    column-gap: 25px;
    row-gap: 10px;
    margin-top: 40px;
}


.user-edit-topup-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    column-gap: 10px;
}

.user-edit-topup-row > input {
    width: 30%;
}

.user-edit-additional {
    margin-top: 30px;
}

.user-edit-additional > h4 {
    margin-bottom: 10px;
}