* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Calibri", sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    color: #303030;
    background: #F4F4F4;
}

@media screen and (max-width: 1024px) {
    body {
        font-size: 14px;
    }
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
    font-family: 'Calibri';
    src: local('Calibri'), url(./fonts/Calibri/Calibri-Regular.ttf);
}

#root {
    height: 100%;
}


:root {
    --content-width: 1178px;
}

.container {
    position: relative;
    height: 100%;
}

.content {
    width: 1178px;
    max-width: 1178px;
    margin: 0 auto;
    height: 100%;
}

@media screen and (max-width: 1024px) {
    .content {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
}

textarea {
    border-radius: 1px;
    border: 1px solid #DDDFE1 !important;
    padding: 4px;
    width: 100%;
}

input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="number"], select {
    width: 100%;
    font-size: 16px;
    padding: 4px;
    border-radius: 1px;
    border: 1px solid #DDDFE1 !important;
    transition: all .2s;
    color: #303030;
    height: 35px;
}

select {
    color: #989898;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, input[type="tel"]:focus, input[type="number"]:focus {
    outline: none 0 !important;
    border-color: #2F80ED !important;
}

input:focus + label,
input:not(:placeholder-shown) + label {
    transform: translateY(1px) scale(.8);
}

input[type="text"]::placeholder, input[type="email"]::placeholder, input[type="password"]::placeholder, input[type="tel"]::placeholder, input[type="number"]::placeholder {
    /*color: transparent;*/
    color: #ABAFB3;
}

input[type="text"]:focus:invalid, input[type="email"]:focus:invalid, input[type="password"]:focus:invalid, input[type="tel"]:focus:invalid, input[type="number"]:focus:invalid {
    border-color: #D8000C !important;
}

input[type="checkbox"] {
    margin-right: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.checkbox-label {
    font-size: 12px;
}

.checkbox-container {
    display: flex;
    align-items: center;
}

/*label {*/
/*    cursor: auto;*/
/*    position: absolute;*/
/*    top: -20px;*/
/*    left: 2px;*/
/*    color: #AAA;*/
/*    display: block;*/
/*    opacity: 1;*/
/*    transform: translateY(1.25em);*/
/*    transform-origin: 0 0;*/
/*    transition: all .2s;*/
/*    z-index: 0;*/
/*}*/

button {
    width: auto;
    min-width: 97px;
    height: 35px;
    border-radius: 2px;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 17px;
    border: none;
    cursor: pointer;
    padding: 0 5px;
}

.upload-button {
    height: 35px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.button-large {
    width: 100% !important;
}

.button-blue {
    background: #2F80ED !important;
    color: #FFFFFF !important;
}

.button-green {
    background: #219653 !important;
    color: #FFFFFF !important;
}

.button-red {
    background: #FF647C !important;
    color: #FFFFFF !important;
}

.button-grey {
    background: #D0C9D6 !important;
    color: #FFFFFF !important;
}

.button-purple {
    background: #BE52F2 !important;
    color: #FFFFFF !important;
}

.button-white {
    background: #FFFFFF !important;
    color: #636363 !important;
    border: 1px solid #939393;
    border-radius: 1px;
}


.link {
    color: #6979F8;
    cursor: pointer;
}

.link-underline {
    text-decoration: underline;
    cursor: pointer;
    padding: 0 5px;
}

.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    display: block;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.error {
    font-size: 14px;
    color: #D8000C;
}

.input-error {
    font-size: 12px;
    color: #D8000C;
    position: absolute;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
}

.logo {
    background: url("./images/logo.png") no-repeat;
    background-size: contain;
    width: 180px;
    height: 17px;
    cursor: pointer;
    flex-grow: 1;
}

.pack {
    display: flex;
    justify-content: left;
    align-items: center;
}

.pack > img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.action-icon {
    cursor: pointer;
}

.action-icon.active {
    color: green !important;
}

.action-icon-container {
    display: flex;
    column-gap: 5px;
}

.table > thead > tr > th {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 7px;
}

.table > tbody > tr > td {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 7px;
}

.block {
    padding: 20px;
    background: #FFF;
    box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
}

@media screen and (max-width: 1024px){
    .block {
        padding: 10px;
    }
}


.title {
    font-weight: 400;
    font-size: 18px;
    color: #7F63F4;
    margin-bottom: 10px;
}

.td-label {
    color: #FFFFFF;
    padding: 2px 5px;
    border-radius: 3px;
}

.td-label-gray {
    background: darkgray;
}

.td-label-green {
    background: #52BE80;
}

.td-label-blue {
    background: #5DADE2;
}

.td-label-orange {
    background: #F39C12
}

.td-label-red {
    background: #FF6347
}

.td-label-purple {
    background: #7F63F4;
}

.td-label-black {
    background: #000;
}

.td-label-pink {
    background: pink;
}

.td-label-limegreen {
    background: limegreen;
}

.search-panel {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

@media screen and (max-width: 1024px){
    .search-panel {
        flex-direction: column;
    }
}

.radio {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.reset-filters {
    position: absolute;
    bottom: 18px;
    cursor: pointer;
    color: gray;
    font-size: 14px;
}

@media screen and (max-width: 1024px){
    .reset-filters {
        margin-bottom: -50px;
        text-align: center;
        width: 100%;
    }
}